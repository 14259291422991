<template>
  <div>
    <NavBar />

    <!-- inner-hero start -->
    <section class="inner-hero bg_img">
      <div class="shape position-absolute">
        <img src="@/assets/images/elements/inner-hero/shape.png" alt="image" />
      </div>

      <div class="el-1 position-absolute">
        <img src="@/assets/images/elements/inner-hero/el-1.png" alt="image" />
      </div>
      <div class="el-2 position-absolute">
        <img src="@/assets/images/elements/inner-hero/el-2.png" alt="image" />
      </div>
      <div class="el-3 position-absolute">
        <img src="@/assets/images/elements/inner-hero/el-3.png" alt="image" />
      </div>
      <div class="el-4 position-absolute">
        <img src="@/assets/images/elements/inner-hero/el-4.png" alt="image" />
      </div>
      <div class="el-5 position-absolute">
        <img src="@/assets/images/elements/inner-hero/el-5.png" alt="image" />
      </div>
      <div class="el-6 position-absolute">
        <img src="@/assets/images/elements/inner-hero/el-6.png" alt="image" />
      </div>

      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <h2 class="page-title">Juegos</h2>
            <ul class="page-list">
              <li><a href="index.html">Inicio</a></li>
              <li>Juegos</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <!-- inner-hero end -->

    <!-- featured game section start -->
    <section class="pt-120 pb-120 bg_img" style="display: none;">
      <div class="container">
        <div class="row justify-content-between">
          <div class="col-lg-6">
            <div class="section-header style--two">
              <span class="section-sub-title">EVENTO DESTACADO</span>
              <h2 class="section-title">Nombre Juego</h2>
            </div>
            <p>
              Cras vestibulum consequat nunc id pellentesque. Vivamus vitae
              auctor risus. Nulla fringilla dolor et velit laoreet eleifend. Nam
              ultricies nisi lacus, eu sagittis eros consectetur a. Vivamus
              ultricies vitae arcu sit amet cursus.
            </p>
            <a href="#0" class="cmn-btn style--two mt-5">learn more</a>
          </div>
          <div class="col-xl-4 col-lg-5">
            <div class="featured-game-thumb text-center">
              <img
                src="@/assets/images/elements/featured-game.png"
                alt="image"
              />
              <p class="mt-3 mb-3">Descárgalo en:</p>
              <div class="d-flex flex-wrap justify-content-center">
                <a href="#0"
                  ><img
                    src="@/assets/images/elements/apple-btn.png"
                    alt="image"
                    class="mr-3"
                /></a>
                <a href="#0"
                  ><img
                    src="@/assets/images/elements/google-btn.png"
                    alt="image"
                    class="ml-3"
                /></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- featured game section end -->

    <!-- game section start -->
    <section class="pt-120 pb-120">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-8">
            <div class="section-header text-center has--bg">
              <div class="header-image">
                <img
                  src="@/assets/images/elements/header-el-5.png"
                  alt="image"
                />
              </div>
              <h2 class="section-title">Nuestros Juegos</h2>
              <p>
                Elige el juego que prefieras, suma puntos y participa por
                increibles premios, recuerda que mientras mas juegues, más
                posibilidades tienes de ganar.
              </p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="games-search-wrapper" style="display: none">
              <div class="left">
                <form class="games-filter-from">
                  <div class="row align-items-center">
                    <div class="col-lg-2 mb-lg-0 mb-2">
                      <span>SORT BY:</span>
                    </div>
                    <div class="col-lg-5 col-md-6 mb-md-0 mb-3">
                      <select class="nice-select">
                        <option>All Platform</option>
                        <option>Platform</option>
                        <option>Platform</option>
                        <option>Platform</option>
                        <option>Platform</option>
                      </select>
                    </div>
                    <div class="col-lg-5 col-md-6">
                      <select class="nice-select">
                        <option>All Platform</option>
                        <option>Platform</option>
                        <option>Platform</option>
                        <option>Platform</option>
                        <option>Platform</option>
                      </select>
                    </div>
                  </div>
                </form>
              </div>
              <div class="right">
                <form class="games-search-from">
                  <input
                    type="search"
                    name="games-search-text"
                    placeholder="Search"
                    class="form-control style--two"
                  />
                  <button type="submit"><i class="las la-search"></i></button>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-50 mb-none-40">

          <div class="col-lg-6 mb-40" v-for="game in gameList" :key="game.id">
            <div class="game-card">
              <div class="game-card__thumb">
                <img :src="game.background" :alt="game.name" />
              </div>
              <div class="game-card__content">
                <div class="game-card__details">
                  <div class="game-card__info">
                    <div class="thumb">
                      <img :src="game.icon" :alt="game.name" />
                    </div>
                    <div class="content">
                      <h3 class="game-card__title">
                        <a href="#play-game">{{ game.name }}</a>
                      </h3>
                      <a v-if="game.platforms.web" :href="game.platforms.web">
                        <img
                            src="https://dg-web-resources.s3-sa-east-1.amazonaws.com/games/web-btn.png"
                            :alt="game.name"
                            width="133"
                            height="52"
                        />
                      </a>
                      <a v-if="game.platforms.ios" :href="game.platforms.ios">
                        <img
                            src="https://dg-web-resources.s3-sa-east-1.amazonaws.com/games/apple-btn.png"
                            :alt="game.name"
                        />
                      </a>
                      <a v-if="game.platforms.android" :href="game.platforms.android">
                        <img
                            src="https://dg-web-resources.s3-sa-east-1.amazonaws.com/games/google-btn.png"
                            :alt="game.name"
                        />
                      </a>
                    </div>
                  </div>
                  <div class="game-card__ratings">
                    <i :class="star.class" v-for="star in setStarClass(game.ranking)" :key="star.id"></i>
                    <span>({{ game.ranking.toFixed(1) }})</span>
                  </div>
                </div>
                <div class="game-card__action"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- game section end -->

    <Footer />
  </div>
</template>

<script>
import axios from "axios";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import router from "../router/index";

export default {
  components: {
    Footer,
    NavBar,
  },
  data() {
    return {
      gameList: []
    }
  },
  beforeMount() {
    axios.get("https://reqres.in/api/users?page=1")
      .then((response) => {
        if (response.status != 200) {
          return; // TODO
        }

        return response.data.data;
      })
      .then((games) => {
        let self = this;
        // TODO
        games.forEach(function(item, index) {
          if (index == 0) {
            self.gameList.push({
              id: 1,
              name: "Galaxy Defender",
              icon: "https://dg-web-resources.s3-sa-east-1.amazonaws.com/games/galaxy-defender-logo.jpg",
              background: "https://dg-web-resources.s3-sa-east-1.amazonaws.com/games/galaxy-defender-background.jpg",
              ranking: 5,
              platforms: {
                web: '/games/galaxy_defender',
                ios: null,
                android: null
              }
            });
          } else if (index == 1) {
            self.gameList.push({
              name: "Entel Race",
              icon: "https://dg-web-resources.s3-sa-east-1.amazonaws.com/games/entel-race-logo.jpg",
              background: "https://dg-web-resources.s3-sa-east-1.amazonaws.com/games/entel-race-background.jpg",
              ranking: 4,
              platforms: {
                web: '/games/entel_race',
                ios: null,
                android: null
              }
            });
          }
        });
      })
      .catch((error) => {
        // TODO
      });
  },
  methods: {
    setStarClass(ranking) {
      ranking = ranking.toFixed(1);
      let starClass = [];

      for (let i=0; i<5; i++) {
        if (i < ranking) {
          starClass.push({start: i+1, class: "fas fa-star"});
        } else if (i <= ranking && i >= ranking && ranking % 1 > 0) {
          starClass.push({start: i+1, class: "fas fa-star-half-alt"});
        } else {
          starClass.push({start: i + 1, class: "far fa-star"});
        }
      }

      return starClass;
    }
  }
};
</script>

<style lang="sass" scoped>
.inner-hero.bg_img
  background-image: url("../assets/images/bg/games-background.png")

.footer-section.bg_img
  background-image: url("../assets/images/elements/footer.png")

.pt-120.pb-120.bg_img 
  background-image: url("../assets/images/bg/featured.png")

</style>
