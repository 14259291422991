<template>
  <header class="header">
    <div class="header__bottom">
      <div class="container">
        <nav class="navbar navbar-expand-xl align-items-center">
          <a class="site-logo site-title" href="index.html">
            <img src="@/assets/images/logo.png" alt="site-logo" />
            <span class="logo-icon">
              <i class="flaticon-fire"></i>
            </span>
          </a>
          <button
            class="navbar-toggler ml-auto"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="menu-toggle"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav main-menu ml-auto">
              <li class="menu_has_children">
                <a :style="current == 'games' ? customStyle : ''" :href="current == 'games' ? '#' : '/games'">Juegos</a>
              </li>
              <li class="menu_has_children">
                <a :style="current == 'winners' ? customStyle : ''" :href="current == 'winners' ? '#' : '/users/winners'">Ganadores</a>
              </li>
              <li class="menu_has_children">
                <a :style="current == 'user' ? customStyle : ''" :href="current == 'user' ? '#' : '/users/:id'">Perfil</a>
              </li>
              <li class="menu_has_children">
                <a :style="current == 'contact' ? customStyle : ''" :href="current == 'contact' ? '#' : '/contact'">Contacto</a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  </header>
</template>

<script>
import router from "../router/index";

export default {
  data() {
    return {
      current: "",
      customStyle: "color: #ff0000;"
    }
  },
  beforeMount() {
    this.current = router.currentRoute.name.toLocaleLowerCase();
  }
};
</script>